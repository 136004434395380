import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/Chooser.tsx";
import '../styles/index.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Container = makeShortcode("Container");
const Image = makeShortcode("Image");
const Heading = makeShortcode("Heading");
const Columns = makeShortcode("Columns");
const Column = makeShortcode("Column");
const Card = makeShortcode("Card");
const CardHeader = makeShortcode("CardHeader");
const CardHeaderTitle = makeShortcode("CardHeaderTitle");
const CardContent = makeShortcode("CardContent");
const Content = makeShortcode("Content");
const CardFooter = makeShortcode("CardFooter");
const CardFooterItem = makeShortcode("CardFooterItem");
const Button = makeShortcode("Button");
const PromotionSection = makeShortcode("PromotionSection");
const CardImage = makeShortcode("CardImage");
const TeamSection = makeShortcode("TeamSection");
const NewsSection = makeShortcode("NewsSection");
const VideosSection = makeShortcode("VideosSection");
const RevealFade = makeShortcode("RevealFade");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section id='head' size='small' textAlign='center' backgroundColor='light' style={{
      position: 'relative',
      backgroundColor: '#f8fff8' //backgroundImage: 'url(/background.png)',
      // backgroundSize: 'cover',
      //backgroundPosition: '50% 30%',
      //padding: '5em',
      //backgroundRepeat: 'repeat',

    }} mdxType="Section">
  <Container mdxType="Container">
    <Image src='/logo.png' alt='ENIA' style={{
          width: 300,
          height: 150,
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto'
        }} mdxType="Image" />
    <Heading renderAs='p' size={3} subtitle className='handwriting' mdxType="Heading">
      gaz renouvelable et social
    </Heading>
  </Container>
    </Section>
    <Section id='whatwedo' size='medium' mdxType="Section">
  <Container mdxType="Container">
    <Heading renderAs='h2' size={2} align='center' mdxType="Heading">
      Les <strong className='highlighted'>métiers</strong> d'Enia
    </Heading>
    <Columns my={8} mdxType="Columns">
      <Column mx={8} mdxType="Column">
        <Card shadowless backgroundColor='light' textColor='black' align='center' display='flex' flexDirection='column' style={{
              height: '100%'
            }} mdxType="Card">
          <CardHeader shadowless mt={4} mdxType="CardHeader">
            <CardHeaderTitle textSize={3} justifyContent='center' mdxType="CardHeaderTitle">
              Approvisionnement en matière organique
            </CardHeaderTitle>
          </CardHeader>
          <CardContent flexGrow={1} mdxType="CardContent">
            <Content size='medium' style={{
                  textAlign: 'left'
                }} mdxType="Content">
              <p>
                Avec plus de <strong className='highlighted'>800 gisements</strong> en France et{' '}
                <strong className='highlighted'>250 acheteurs</strong>, Enia propose la{' '}
                <strong className='highlighted'>première place de marché</strong> des matières organiques françaises
                pour les producteurs de biogaz et de matière organique.
              </p>
            </Content>
          </CardContent>
          <CardFooter mdxType="CardFooter">
            <CardFooterItem mdxType="CardFooterItem">
              <a href='/marketplace'>
                <Button size='medium' fullwidth color='primary' mdxType="Button">
                  Je vends, J'achète
                </Button>
              </a>
            </CardFooterItem>
          </CardFooter>
          <br />
        </Card>
      </Column>
      <Column mx={8} mdxType="Column">
        <Card shadowless backgroundColor='light' textColor='black' align='center' display='flex' flexDirection='column' style={{
              height: '100%'
            }} mdxType="Card">
          <CardHeader shadowless mt={4} mdxType="CardHeader">
            <CardHeaderTitle textSize={3} justifyContent='center' mdxType="CardHeaderTitle">
              Développement &amp; exploitation de projets d'énergie renouvelable
            </CardHeaderTitle>
          </CardHeader>
          <CardContent flexGrow={1} mdxType="CardContent">
            <Content size='medium' style={{
                  textAlign: 'left'
                }} mdxType="Content">
              <p>
                Enia vous <strong className='highlighted'>accompagne de A à Z</strong> dans le développement de votre
                projet de méthanisation, bio-déconditionneur, hygiéniseur, etc.
              </p>
            </Content>
          </CardContent>
          <CardFooter mdxType="CardFooter">
            <CardFooterItem mdxType="CardFooterItem">
              <a href='/projets'>
                <Button size='medium' fullwidth color='primary' mdxType="Button">
                  Découvrir nos projets
                </Button>
              </a>
            </CardFooterItem>
          </CardFooter>
          <br />
        </Card>
      </Column>
      <Column mx={8} mdxType="Column">
        <Card shadowless backgroundColor='light' textColor='black' align='center' display='flex' flexDirection='column' style={{
              height: '100%'
            }} mdxType="Card">
          <CardHeader shadowless mt={4} mdxType="CardHeader">
            <CardHeaderTitle textSize={3} justifyContent='center' mdxType="CardHeaderTitle">
              Incubateur de projets sociaux et solidaires
            </CardHeaderTitle>
          </CardHeader>
          <CardContent flexGrow={1} mdxType="CardContent">
            <Content size='medium' style={{
                  textAlign: 'left'
                }} mdxType="Content">
              <p>
                Enia favorise l’ancrage dans l’économie réelle des{' '}
                <strong className='highlighted'>associations à but social et solidaires</strong> en les accueillant à
                proximité de ses sites industriels.
              </p>
            </Content>
          </CardContent>
          <CardFooter mdxType="CardFooter">
            <CardFooterItem mdxType="CardFooterItem">
              <a href='/projets'>
                <Button size='medium' fullwidth color='primary' mdxType="Button">
                  Découvrir nos projets
                </Button>
              </a>
            </CardFooterItem>
          </CardFooter>
          <br />
        </Card>
      </Column>
    </Columns>
  </Container>
    </Section>
    <PromotionSection mdxType="PromotionSection" />
    <Section id='vision' size='medium' backgroundColor='light' mdxType="Section">
  <Container mdxType="Container">
    <Heading renderAs='h2' size={2} align='center' mdxType="Heading">
      La <strong className='highlighted'>vision</strong> d'Enia
    </Heading>
    <hr />
    <Content size='medium' mdxType="Content">
      <blockquote>
        <p>
          Chez Enia, on connaît l'impact fulgurant d'un{' '}
          <strong className='highlighted'>projet pleinement durable</strong> : à la fois sur sa dimension écologique et
          sur sa dimension sociale.
        </p>
        <p>
          Chez Enia, on pense qu'il est vraiment dommage d'aller chercher notre gaz au fond de la terre alors que{' '}
          <strong className='highlighted'>nous avons une biomasse quasiment illimitée en surface.</strong>
        </p>
        <p>
          On pense que valoriser cette biomasse en gaz renouvelable permettrait de{' '}
          <strong className='highlighted'>réduire nos émissions</strong> de gaz à effet de serre, et de réduire le
          réchauffement climatique.
        </p>
        <p>
          On pense aussi qu'en France, on a intérêt à <strong className='highlighted'>réduire notre dépendance</strong>{' '}
          aux énergies achetées à l'étranger.
        </p>
        <p>
          On pense enfin que le biogaz est une <strong className='highlighted'>filière d'avenir</strong> qui a besoin
          des nouvelles technologies pour se libérer !
        </p>
        <p>
          Chez Enia, on croit qu'il n’y a rien de plus bénéfique qu’un{' '}
          <strong className='highlighted'>projet social</strong> qui s’ancre dans l’écologie.
        </p>
        <p>
          On croit que le développement d'énergies renouvelables doit être accompagné du développement des{' '}
          <strong className='highlighted'>sociétés plus équitables ou inclusives</strong>.
        </p>
        <p>
          On croit que des milliers de personnes peuvent trouver{' '}
          <strong className='highlighted'>un sens et un emploi</strong> dans les énergies renouvelables.
        </p>
        <p>
          On croit aussi que c'est un <strong className='highlighted'>enjeu local</strong> qui doit s'adresser aux
          populations locales en lien avec leurs représentants publics.
        </p>
      </blockquote>
    </Content>
  </Container>
    </Section>
    <Section id='references' size='medium' mdxType="Section">
  <Container mdxType="Container">
    <Heading renderAs='h2' size={2} align='center' mdxType="Heading">
      Nos <strong className='highlighted'>références</strong>
    </Heading>
    <hr />
    <Columns my={6} centered mdxType="Columns">
      <Column size={3} mobile={{
            size: 12,
            display: 'inline-block'
          }} mdxType="Column">
        <Card align='center' display='flex' flexDirection='column' style={{
              height: '100%'
            }} mdxType="Card">
          <CardImage loading='lazy' src='/clients/eve.png' alt='EVE' p={4} m={2} mb={4} style={{
                backgroundColor: '#1e9898'
              }} mdxType="CardImage" />
          <CardContent flexGrow={1} mdxType="CardContent">
            <Content mdxType="Content">
              <p>
                Enia accompagne la société Energies à Vocation Environnementale (ÈVE) dans la sécurisation de leur plan
                d'approvisionnement (environ 100 000 tonnes par an) pour 3 projets de méthanisation territoriale en PACA
                et en Bourgogne Franche Comté.
              </p>
            </Content>
          </CardContent>
        </Card>
      </Column>
      <Column size={3} mobile={{
            size: 12,
            display: 'inline-block'
          }} mdxType="Column">
        <Card align='center' display='flex' flexDirection='column' style={{
              height: '100%'
            }} mdxType="Card">
          <CardImage loading='lazy' src='/clients/cve.png' alt='CVE' p={4} m={2} mdxType="CardImage" />
          <CardContent flexGrow={1} mdxType="CardContent">
            <Content mdxType="Content">
              <p>
                Enia a réalisé la prospection pour le compte du site de Bannalec (22), en recherche de 10 000 tonnes de
                matières organiques complémentaires en vue de l'augmentation de la production de l'unité.
              </p>
            </Content>
          </CardContent>
        </Card>
      </Column>
      <Column size={3} mobile={{
            size: 12,
            display: 'inline-block'
          }} mdxType="Column">
        <Card align='center' display='flex' flexDirection='column' style={{
              height: '100%'
            }} mdxType="Card">
          <CardImage loading='lazy' src='/clients/totalenergies.svg' alt='TotalEnergies' px={6} mb={2} mdxType="CardImage" />
          <CardContent flexGrow={1} mdxType="CardContent">
            <Content mdxType="Content">
              <p>
                Enia accompagne TotalEnergies dans la sécurisation de substrats en vue d'étudier la faisabilité d'un
                projet d'unité de méthanisation en Ile-de-France.
              </p>
            </Content>
          </CardContent>
        </Card>
      </Column>
      <Column size={3} mobile={{
            size: 12,
            display: 'inline-block'
          }} mdxType="Column">
        <Card align='center' display='flex' flexDirection='column' style={{
              height: '100%'
            }} mdxType="Card">
          <CardImage loading='lazy' src='/clients/briebioenergie.png' alt='Brie Bio Energie' px={6} my={4} mb={5} mdxType="CardImage" />
          <CardContent flexGrow={1} mdxType="CardContent">
            <Content mdxType="Content">
              <p>
                L'unité de méthanisation BrieBioEnergie (77) confie à Enia l'atteinte d'un second palier de production,
                souhaitant passer de 180 Nm³ à 250 Nm³ de gaz injecté en augmentant le volume d'intrants de 6 000 tonnes
                de matière brute.
              </p>
            </Content>
          </CardContent>
        </Card>
      </Column>
    </Columns>
  </Container>
    </Section>
    <Section id='team' size='medium' backgroundColor='light' mdxType="Section">
  <Container mdxType="Container">
    <Heading renderAs='h2' size={2} align='center' mdxType="Heading">
      La <strong className='highlighted'>team</strong> d'Enia
    </Heading>
    <hr />
  </Container>
  <TeamSection mdxType="TeamSection" />
    </Section>
    <Section id='news' size='medium' mdxType="Section">
  <Container mdxType="Container">
    <Heading renderAs='h2' size={2} align='center' mdxType="Heading">
      Actualités biogaz
    </Heading>
    <hr />
    <NewsSection mdxType="NewsSection" />
  </Container>
    </Section>
    <Section id='video' size='small' mdxType="Section">
  <Heading renderAs='h2' size={2} align='center' mdxType="Heading">
    En vidéo
  </Heading>
  <hr />
  <VideosSection mdxType="VideosSection" />
    </Section>
    {
      /*  <AppSection />  */
    }
    <Section id='green' size='medium' style={{
      backgroundColor: '#f8fff8',
      backgroundImage: 'url(https://images.unsplash.com/photo-1458014854819-1a40aa70211c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80)',
      backgroundSize: 'cover',
      backgroundPosition: '50% 20%'
    }} mdxType="Section">
  <Container mdxType="Container">
    <RevealFade mdxType="RevealFade">
      <Heading renderAs='p' size={3} className='handwriting' mdxType="Heading">
        Participez au développement du biogaz sur votre territoire
      </Heading>
    </RevealFade>
  </Container>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      