import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import * as Layouts from '.'
import { shortcodes } from './Layouts.helpers'

const Chooser: React.FC = ({ children, pageContext }) => {
  // Identify the layout name & load it
  const layout = pageContext.frontmatter.layout || 'Default'
  const Layout = Layouts[`${layout}Layout`]

  return (
    <MDXProvider components={shortcodes}>
      <Layout pageContext={pageContext}>{children}</Layout>
    </MDXProvider>
  )
}

export default Chooser
